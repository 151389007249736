import { BodyText } from 'components/common/Typography';
import React from 'react';

export const ErrorHeroSubHeading = React.memo(
  ({ subheadingText, className }: { subheadingText: string } & ParentClassNameProp) => {
    return (
      <BodyText large align="center" className={className}>
        {subheadingText}
      </BodyText>
    );
  }
);

ErrorHeroSubHeading.displayName = 'ErrorHeroSubHeading';
