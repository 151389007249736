import { useHideDriftWidget } from 'hooks/useDriftWidget';
import { noop } from 'lib/backfillData';
import React, { useCallback } from 'react';

import { useKeydownEscapeListener } from 'hooks/useKeyboard';
import { Form, Props as FormProps } from '../Form';
import { ModalContent } from './ModalContent';
import { ModalContextType } from './ModalContext';
import { ModalWrapper } from './ModalWrapper';

export type ModalProps = ParentClassNameProp & {
  as?: 'form' | 'div';
  open?: boolean;
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBack?: GenericHandler;
  showBack?: boolean;
  onClose?: GenericHandler;
  showClose?: boolean;
  childrenClassName?: string;
  contentClassName?: string;
  closeButtonClassName?: string;
  size?: 'sm' | 'md' | 'lg' | 'full';
  panelClassName?: string;
  hasMobileFullHeight?: boolean;
  hasPanelFlexWrap?: boolean;
  hasOuterPadding?: boolean;
  disableOverflowY?: boolean;
  disableEscapeKey?: boolean;
  justifyContent?: 'center' | 'start';
  icon?: React.ReactNode;
  ref?: React.Ref<HTMLFormElement>;
  defaultPadding?: boolean;
} & ModalContextType &
  Pick<Partial<FormProps>, 'onSubmit'> &
  ChildrenProp;

export type StyledProps = Pick<
  ModalProps,
  'title' | 'className' | 'childrenClassName' | 'contentClassName' | 'defaultPadding'
>;

export const Modal: React.ComponentType<ModalProps> = React.forwardRef<HTMLFormElement, ModalProps>(
  (props, ref) => {
    const {
      title,
      childrenClassName,
      contentClassName,
      closeButtonClassName,
      footer,
      children,
      onClose,
      showClose,
      onBack,
      showBack,
      open,
      toggleState,
      disableEscapeKey,
      onSubmit,
      as
    } = props;

    useHideDriftWidget(open ?? toggleState?.status);

    useKeydownEscapeListener({
      enable: !disableEscapeKey && open,
      callback: useCallback(
        (e: KeyboardEvent) => {
          onClose && onClose(e);
          toggleState?.disable(e);
        },
        [onClose, toggleState]
      )
    });

    const WrapperComponent = as === 'form' ? Form : 'div';
    const WrapperComponentProps = { onSubmit, className: 'w-full' };

    return (
      <ModalWrapper ref={ref} {...props}>
        <WrapperComponent {...WrapperComponentProps}>
          <ModalContent
            title={title}
            footer={footer}
            childrenClassName={childrenClassName}
            contentClassName={contentClassName}
            closeButtonClassName={closeButtonClassName}
            onClose={onClose}
            showClose={showClose}
            onBack={onBack}
            defaultPadding={props.defaultPadding}
            showBack={showBack}>
            {children}
          </ModalContent>
        </WrapperComponent>
      </ModalWrapper>
    );
  }
);

Modal.defaultProps = {
  showClose: true,
  onClose: noop,
  onSubmit: noop,
  as: 'div',
  disableEscapeKey: false,
  defaultPadding: true
};

Modal.displayName = 'Modal';
