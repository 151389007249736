import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { ErrorHeroHeading } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroHeading';
import { ErrorHeroIcon } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroIcon';
import { ErrorHeroSubHeading } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroSubHeading';
import { ErrorLinkButton } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorLinkButton';
import { ErrorResetButton } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorResetButton';
import {
  ErrorCodesProp,
  useErrorPageHeroContentStyles
} from 'components/ErrorPage/ErrorPageHero/ErrorPageHeroBackground';

export const useStyles = makeStyles({
  outerWrapper: 'flex relative bg-neutralBackgroundSoft px-6 max-w-4xl',
  contentOuterWrapper: 'absolute z-2 w-full',
  contentWrapper: ['w-full px-6', 'flex flex-col items-center justify-center'],
  buttonRow: 'flex flex-row space-x-3 my-4'
});

export const ErrorPageFallbackContent = React.memo(
  ({ statusCode, onClick }: ErrorCodesProp & ClickEventProp) => {
    const styles = useStyles();

    const errorPageHeroStyles = useErrorPageHeroContentStyles({
      containerClassName: styles.contentOuterWrapper
    });

    return (
      <div className={errorPageHeroStyles.container}>
        <div className={styles.contentWrapper}>
          <ErrorHeroIcon statusCode={statusCode} />
          <ErrorHeroHeading headingText="Road work ahead" />
          <ErrorHeroSubHeading
            subheadingText="Something went wrong! Our team has been notified."
            className="px-2"
          />
          <div className={styles.buttonRow}>
            <ErrorResetButton buttonText="Go Back" onClick={onClick} />
            <ErrorLinkButton buttonText="Continue" />
          </div>
        </div>
      </div>
    );
  }
);

ErrorPageFallbackContent.displayName = 'ErrorPageFallbackContent';
