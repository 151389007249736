import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { ErrorPageFallbackContent } from 'components/ErrorPage/ErrorPageFallback/ErrorFallbackContent';
import {
  ErrorCodesProp,
  ErrorPageHeroBackground
} from 'components/ErrorPage/ErrorPageHero/ErrorPageHeroBackground';
import { ErrorCodes } from 'types/common';

const useStyles = makeStyles({
  root: 'flex relative bg-white',
  wrapper: 'h-screen w-screen',
  gradient: [
    'h-full',
    'bg-gradient-to-t from-20% from-neutralBackgroundLoud/75  lg:from-neutralBackgroundLoud/90'
  ],
  errorCode: ['m-auto', 'text-[50vw] xl:text-[500px] font-extrabold text-neutralBackgroundLoud']
});

export const ErrorPageFallback = React.memo(
  ({ statusCode = ErrorCodes.ERROR, onReset }: ErrorCodesProp & { onReset?: GenericHandler }) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <ErrorPageHeroBackground
          statusCode={statusCode}
          wrapperClassName={styles.wrapper}
          gradientClassName={styles.gradient}
          errorCodeClassName={styles.errorCode}
        />
        <ErrorPageFallbackContent statusCode={statusCode} onClick={onReset} />
      </div>
    );
  }
);

ErrorPageFallback.displayName = 'ErrorPageFallback';
