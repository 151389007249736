import vercelAnalytics from '@vercel/analytics';
import { isGTMInitialized } from 'lib/analytics/gtm';
import { truckMapConfig } from 'truckMapConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pingEvent(eventName: string, ...args: any[]) {
  try {
    if (!isGTMInitialized()) return;

    const normalizedEventName = eventName.toUpperCase().replaceAll(' ', '_');
    window.dataLayer = window.dataLayer || [];

    if (
      truckMapConfig.isClientSideRender &&
      !truckMapConfig.isStaging &&
      !truckMapConfig.isTenstreet
    ) {
      window.dataLayer.push({
        event: normalizedEventName,
        ...args
      });

      vercelAnalytics.track(normalizedEventName, (args?.length && args[0]) || undefined);
    }
  } catch (error) {
    console.error('Failed to track GTM event: ', {
      eventName,
      error
    });
  }
}
