import { PanelItem } from 'components/layout/Panel/PanelItem';
import React from 'react';

import { MetaText } from '../Typography';
import { ListOption } from './DropdownListOptions';

export type Props = {
  active: boolean;
  selected: boolean;
} & Pick<ListOption, 'value' | 'disabled' | 'itemLabel'>;

export const DropdownListOption = React.memo(
  ({ active, selected, value, disabled, itemLabel }: Props) => {
    return (
      <PanelItem
        className="group"
        paddingY="SM"
        paddingX="SM"
        intent="primary"
        enableBackground
        enableHover
        showDivider
        active={active || selected}
        disabled={disabled}>
        {itemLabel || typeof value === 'string' ? (
          <MetaText as="span" active={active || selected} disabled={disabled} enableHover="group">
            {itemLabel ?? value}
          </MetaText>
        ) : (
          value
        )}
      </PanelItem>
    );
  }
);

DropdownListOption.displayName = 'DropdownListOption';
