import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

import { ErrorHeroHeading } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroHeading';
import { ErrorHeroIcon } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroIcon';
import { ErrorHeroSubHeading } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorHeroSubHeading';
import { ErrorLinkButton } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorLinkButton';
import { ErrorResetButton } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorResetButton';
import { ErrorCodesProp, useErrorPageHeroContentStyles } from '../ErrorPageHeroBackground';

type Props = Pick<UIComponentProps, 'entry'> & ErrorCodesProp;

export const useStyles = makeStyles({
  outerWrapper: 'flex relative bg-neutralBackgroundSoft px-6 max-w-4xl',
  contentOuterWrapper: 'absolute z-2 w-full',
  contentWrapper: ['w-full px-6', 'flex flex-col items-center justify-center'],
  buttonRow: 'flex flex-row space-x-3 my-4'
});

const defaultButtonHref = '/';

export const ErrorPageHeroContent = React.memo(({ statusCode, entry }: Props) => {
  const styles = useStyles();
  const { richTextSections, items } = entry;
  const { t } = useTranslation();
  const primaryButton = items.find((item) => item.type === 'Button');
  const [heading, subheading] = richTextSections;

  const errorPageHeroStyles = useErrorPageHeroContentStyles({
    containerClassName: styles.contentOuterWrapper
  });

  return (
    <div className={errorPageHeroStyles.container}>
      <div className={styles.contentWrapper}>
        <ErrorHeroIcon statusCode={statusCode} />
        {heading && <ErrorHeroHeading headingText={heading.primaryText} />}
        {subheading && <ErrorHeroSubHeading subheadingText={subheading.primaryText} />}
        <div className={styles.buttonRow}>
          <ErrorResetButton buttonText={t('GO_BACK')} />
          {primaryButton && (
            <ErrorLinkButton
              href={primaryButton.primaryLink?.url ?? defaultButtonHref}
              buttonText={primaryButton.primaryText ?? t('CONTINUE')}
            />
          )}
        </div>
      </div>
    </div>
  );
});

ErrorPageHeroContent.displayName = 'ErrorPageHeroContent';
