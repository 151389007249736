'use client';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

import { usePageContext } from 'components/Page/PageContext';
import { ErrorCodes } from 'types/common';
import { ErrorPageHeroContent } from './ErrorHeroContent/ErrorHeroContent';
import { ErrorPageHeroBackground } from './ErrorPageHeroBackground';

const useStyles = makeStyles({
  outerWrapper: 'flex relative bg-primaryBackground'
});

export const ErrorPageHero = React.memo((props: UIComponentProps) => {
  const styles = useStyles();
  const { statusCode = ErrorCodes.NOT_FOUND } = usePageContext();

  return (
    <div className={styles.outerWrapper}>
      <ErrorPageHeroBackground statusCode={statusCode} />
      <ErrorPageHeroContent statusCode={statusCode} {...props} />
    </div>
  );
});

ErrorPageHero.displayName = 'ErrorPageHero';
