import { RoadWorkIcon } from 'icons/roadSigns/RoadWorkIcon';
import { UTurnIcon } from 'icons/roadSigns/UTurnIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { ErrorCodes } from 'types/common';

import { ErrorCodesProp } from '../ErrorPageHeroBackground';

export const useStyles = makeStyles({
  icon: 'w-[20vw] min-w-[120px] max-w-[200px] h-auto'
});

export const ErrorHeroIcon = React.memo(
  ({ statusCode, className }: ErrorCodesProp & ParentClassNameProp) => {
    const styles = useStyles();

    return (
      <div className={className ?? styles.icon}>
        {statusCode === ErrorCodes.NOT_FOUND ? (
          <UTurnIcon className={styles.icon} />
        ) : (
          <RoadWorkIcon className={styles.icon} />
        )}
      </div>
    );
  }
);

ErrorHeroIcon.displayName = 'ErrorHeroIcon';
