import { Button } from 'components/common/Button';
import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';

export type ErrorResetButtonProps = ClickEventProp & { buttonText?: string };

export const ErrorResetButton = React.memo(
  ({ onClick, buttonText = 'Reset' }: ErrorResetButtonProps) => {
    const router = useRouter();

    const handleClickCallback = useCallback(
      () => (onClick ? onClick : () => router.back()),
      [onClick, router]
    );

    return (
      <Button as="div" dark onClick={handleClickCallback}>
        {buttonText}
      </Button>
    );
  }
);

ErrorResetButton.displayName = 'ErrorResetButton';
