import GTM from 'react-gtm-module-custom-domain';
import { truckMapConfig } from 'truckMapConfig';

const { apiKeys, url, gtmFile } = truckMapConfig;

export const initializeGTM = () => {
  // Prevent double initialization
  if (!truckMapConfig.isClientSideRender || isGTMInitialized()) {
    return;
  }

  try {
    GTM.initialize({
      gtmId: apiKeys.gtm.id,
      customURL: `${url}${gtmFile}`,
      events: {
        gtm_init: true
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__GTM_INITIALIZED__ = true;
  } catch (error) {
    console.error('Failed to initialize GTM: ', error);
  }
};

export const isGTMInitialized = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(window as any).__GTM_INITIALIZED__;
};
