'use client';

import * as Sentry from '@sentry/nextjs';
import { ErrorPageFallback } from 'components/ErrorPage/ErrorPageFallback/ErrorPageFallback';
import { Page } from 'components/Page';
import { LoadingScreen } from 'components/common/LoadingSpinner/LoadingScreen';
import { useEffect, useState } from 'react';
import { ErrorCodes, StaticPageProps } from 'types/common';

export default function Error({ error, reset }: { error: Error; reset: GenericHandler }) {
  const [pageData, setPageData] = useState<StaticPageProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/api/error-page')
      .then((res) => res.json())
      .then(setPageData)
      .catch(() => setPageData(null))
      .finally(() => setIsLoading(false));
  }, []);

  // Sentry
  useEffect(() => {
    const handleError = async () => {
      if (error?.message?.includes('404')) return;
      Sentry.captureException(error);
      await Sentry.flush(2000);
    };
    handleError();
  }, [error]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!pageData) {
    return <ErrorPageFallback onReset={reset} statusCode={ErrorCodes.ERROR} />;
  }

  return <Page {...pageData} statusCode={ErrorCodes.ERROR} />;
}
