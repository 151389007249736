import { Button } from 'components/common/Button';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import React from 'react';

import { ErrorResetButtonProps } from 'components/ErrorPage/ErrorPageHero/ErrorHeroContent/ErrorResetButton';

const defaultButtonHref = '/';

type ErrorLinkButton = Pick<ErrorResetButtonProps, 'buttonText'> & { href?: string };

export const ErrorLinkButton = React.memo(
  ({ href = defaultButtonHref, buttonText }: ErrorLinkButton) => {
    return (
      <NonPrefetchLink href={href}>
        <Button as="div" isPrimary>
          {buttonText}
        </Button>
      </NonPrefetchLink>
    );
  }
);

ErrorLinkButton.displayName = 'ErrorLinkButton';
