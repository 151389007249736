'use client';
import { Container } from 'components/layout/Container';
import { makeStyles } from 'lib/makeStyles';
import { ErrorCodes } from 'types/common';

export type ErrorPageCustomStyleProps = {
  wrapperClassName?: string;
  containerClassName?: string;
  gradientClassName?: string;
  errorCodeClassName?: string;
};

export type ErrorCodesProp = { statusCode: ErrorCodes };

export const useErrorPageHeroContentStyles = makeStyles<ErrorPageCustomStyleProps>()({
  wrapper: (props) => ['w-full min-h-[48vh] overflow-hidden', 'z-1', props.wrapperClassName],
  // using gray palette classes here because this is a custom gradient
  gradient: [
    'h-full w-full',
    'bg-gradient-to-t from-neutralBackgroundLoud/75 lg:from-neutralBackgroundLoud/90'
  ],
  errorCode: ['m-auto', 'text-[30vw] xl:text-[400px] font-extrabold text-neutralBackgroundLoud'],
  container: (props) => [
    'h-full',
    'flex justify-center items-center',
    'pt-16 md:pt-0',
    props.containerClassName
  ]
});

export const ErrorPageHeroBackground = ({
  statusCode,
  wrapperClassName,
  gradientClassName,
  errorCodeClassName
}: ErrorCodesProp & ErrorPageCustomStyleProps) => {
  const styles = useErrorPageHeroContentStyles({ wrapperClassName, gradientClassName });

  return (
    <div className={styles.wrapper}>
      <div className={gradientClassName ?? styles.gradient}>
        <Container className={styles.container}>
          <div className={errorCodeClassName ?? styles.errorCode}>{statusCode}</div>
        </Container>
      </div>
    </div>
  );
};

ErrorPageHeroBackground.displayName = 'ErrorPageHeroBackground';
