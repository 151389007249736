import { HeadingText } from 'components/common/Typography';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export const useStyles = makeStyles({
  headingText: 'block my-2'
});

export const ErrorHeroHeading = React.memo(
  ({ className, headingText }: ParentClassNameProp & { headingText?: string }) => {
    const styles = useStyles();

    return (
      <HeadingText size="MD" align="center" className={className ?? styles.headingText}>
        {headingText}
      </HeadingText>
    );
  }
);

ErrorHeroHeading.displayName = 'ErrorHeroHeading';
